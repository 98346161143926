function summarizeUsda(data) {
  const defaultMap = {};
  for (let i = 4; i <= 19; i += 1) {
    defaultMap[i] = {
      count: 0,
      defective: 0,
      name: 'ounces',
      size: i,
      totalWeight: 0
    };
  }

  return Object.values(
    data.reduce((map, d) => {
      const category = Math.max(4, Math.min(19, Math.floor(d.approximateWeightLbs * 16)));
      if (!map[category]) {
        return map;
      }

      map[category].count += 1;
      map[category].defective += (d.cls <= 2 || d.cls > 5 ? 1 : 0);
      map[category].totalWeight += d.approximateWeightLbs;
      return map;
    }, defaultMap)
  );
}

function summarizeSanjeev(data) {
  const sizes = [0, 1.0875, 2.5, 3.25, 3.75];

  const defaultMap = {}
  for (let i = 0; i <= 4; i += 1) {
    defaultMap[i] = {
      count: 0,
      defective: 0,
      name: `Sanjeev${i}`,
      size: sizes[i],
      totalWeight: 0
    };
  }

  return Object.values(
    data.reduce((map, d) => {
      let size = 0;
      for (let i = 1; i < sizes.length; i += 1) {
        const checkSize = sizes[i];
        if (d.spudDiameterInInches < checkSize) {
          break;
        }

        size = i;
      }

      map[size].count += 1;
      map[size].defective += (d.cls <= 2 || d.cls > 5 ? 1 : 0);
      map[size].totalWeight += d.approximateWeightLbs;

      return map;
    }, defaultMap)
  );
}

function summarizeWayne(data) {
  const sizes = [
    { name: 'Undersize', size: 0 },
    { name: 'Small C-size', size: 0.87 },
    { name: 'Large C-size', size: 1.5 },
    { name: 'Small B-size', size: 2 },
    { name: 'Large B-size', size: 2.32 },
    { name: 'Small A-size', size: 2.64 },
    { name: 'Large A-size', size: 3 },
    { name: '9-10 oz.', size: 3.15 },
    { name: '10-13 oz.', size: 3.35 },
    { name: 'Oversize', size: 3.6 }
  ];

  const defaultMap = {}
  for (let i = 0; i < sizes.length; i += 1) {
    defaultMap[i] = {
      count: 0,
      defective: 0,
      name: sizes[i].name,
      size: sizes[i].size,
      totalWeight: 0
    };
  }

  return Object.values(
    data.reduce((map, d) => {
      let size = 0;
      for (let i = 1; i < sizes.length; i += 1) {
        const checkSize = sizes[i].size;
        if (d.spudDiameterInInches < checkSize) {
          break;
        }

        size = i;
      }

      map[size].count += 1;
      map[size].defective += (d.cls <= 2 || d.cls > 5 ? 1 : 0);
      map[size].totalWeight += d.approximateWeightLbs;

      return map;
    }, defaultMap)
  );
}

export default function analyzeRawData(schema, data) {
  if (schema === 'USDA') {
    return summarizeUsda(data);
  }

  if (schema === 'SANJEEV') {
    return summarizeSanjeev(data);
  }

  if (schema === 'WAYNE') {
    return summarizeWayne(data);
  }

  return data;
}
