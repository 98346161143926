import React from "react";

import {
  Input,
  Password,
} from "./elements";

export const FormElement = ({
  element = "input",
  id,
  name,
  onBlur,
  onChange,
  value,
  label,
  placeholder,
}) => {
  const components = {
    input: Input,
    password: Password
  };

  const props = {
    data: [element],
    id,
    name,
    onBlur,
    onChange,
    value,
    label,
    placeholder,
    test: "test"
  };

  const getFormElement = (type) => {
    const Component = components[type] || Input;

    return <Component {...props} />;
  };

  return <>{getFormElement(element)}</>;
};
