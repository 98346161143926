import React, { useEffect } from 'react';
import { Outlet, useSearchParams } from "react-router-dom";

import ProfileContext from '../context/profile-context';

function ProfileLayout() {
  const [searchParams, setSearchParams] = useSearchParams();

  const profile = searchParams.get('profile')
    || localStorage.getItem('profile')
    || 'SANJEEV';

  const setProfile = (newProfile) => {
    setSearchParams((params) => {
      const existingParams = [...params.entries()].reduce((map, entry) => {
        const [key, val] = entry;
        map[key] = val;
        return map;
      }, {});

      return {
        ...existingParams,
        profile: newProfile
      };
    })

    localStorage.setItem('profile', newProfile)
  }

  useEffect(() => {
    if (!searchParams.get('profile')) {
      setSearchParams((params) => {
        const existingParams = [...params.entries()].reduce((map, entry) => {
          const [key, val] = entry;
          map[key] = val;
          return map;
        }, {});


        return {
          ...existingParams,
          profile: profile
        };
      })
    }
  }, [profile, searchParams, setSearchParams]);

  return (
    <ProfileContext.Provider
      value={{ profile, setProfile }}
    >
      <Outlet />
    </ProfileContext.Provider>
  );
}

export default ProfileLayout;
