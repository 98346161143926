import React from "react";
import { Route } from "react-router-dom";

import ProtectedLayout from "../layouts/protected";
import ProfileLayout from "../layouts/profile";

import Main from "../components/main/main";
import Systems from "../pages/systems/systems";
import Users from "../pages/users/users";
import ViewUser from "../pages/view-user/view-user";
import System from "../pages/system/system";

const AdminRouter = (
  <Route element={<ProtectedLayout />}>
    <Route path="/admin" element={<Main/>}>
      <Route path="systems" element={<Systems />} />
      <Route path="users" element={<Users />} />
      <Route path="user/:id" element={<ViewUser />} />
      <Route element={<ProfileLayout />}>
        <Route path="system/:id" element={<System />} />
      </Route>
    </Route>
  </Route>
);

export default AdminRouter;