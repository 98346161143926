import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reloadState: false
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    changeReloadState: (state, action) => {
      //state.reloadState = action.payload
    },
  },
});

export const selectedReloadState = (state) => state.note.reloadState;

export const { changeReloadState } = noteSlice.actions

export const noteReducer = noteSlice.reducer;