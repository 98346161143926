import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Outlet, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import APIClient from '../client';

import AuthContext from "../context/auth-context";

const CLIENT_COOKIE_NAME = 'spudbud_token';
const CLIENT_REFRESH_NAME = 'spudbud_refresh';

function clearAccessToken() {
  console.log('clear!!!!')
  const cookies = new Cookies();
  cookies.remove(CLIENT_COOKIE_NAME, { path: '/' });
}

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const queryRefreshToken = searchParams.get('token');
  const queryBarnId = searchParams.get('barnId');

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const barnId = parsedUser?.barn_id;

  const isRefreshingToken = useRef();

  const logout = useCallback(async () => {
    try {
      console.log('Logging out...');
      clearAccessToken();
      localStorage.removeItem(CLIENT_REFRESH_NAME);
      localStorage.removeItem('user');
      setUserData(null);
      navigate('/login', {});
    } catch (error) {
      console.error('Logout failed to remove user:', error);
    }
  }, [navigate]);

  const saveUserData = useCallback((res) => {
    const { userData: user, refreshToken } = res;

    window.localStorage.setItem("user", JSON.stringify(user));
    if (refreshToken) {
      window.localStorage.setItem(CLIENT_REFRESH_NAME, refreshToken);
    }

    setUserData((state) => ({ ...user }));
  }, []);

  const refresh = useCallback(async (barnId, token) => {
    try {
      const res = await APIClient.post('/auth/refresh', { barnId }, token);
      saveUserData(res);
    } catch (err) {
      // invalid refresh
      if (err.status === 401) {
        logout();
      } else {
        console.error(err);
      }
    }
  }, [logout, saveUserData]);

  async function runProtected(cb, ...args) {
    if (isRefreshingToken.current) {
      await new Promise((resolve) => {
        setInterval(() => {
          if (!isRefreshingToken.current) {
            resolve();
          }
        }, 100);
      });

      return cb(...args);
    }

    await checkToken()
    
    return cb(...args);
  }

  async function checkToken() {
    // refresh token if expired, then run callback
    isRefreshingToken.current = true;
    if (userData.exp < Date.now() / 1000) {
      const token = window.localStorage.getItem(CLIENT_REFRESH_NAME);
      await refresh(barnId, token);
    }
    isRefreshingToken.current = false;
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let data = window.localStorage.getItem("user");
    if (!queryRefreshToken) {
      if (!data) {
        setUserData(null);
        setIsLoading(false);
        return;
      }
    }

    const loadData = async () => {
      data = JSON.parse(data);

      const token = window.localStorage.getItem(CLIENT_REFRESH_NAME);
      //await refresh(data?.barn_id || queryBarnId, token || queryRefreshToken);

      setUserData(data);
      setIsLoading(false);
    };

    loadData();
  }, [refresh, isLoading, queryBarnId, queryRefreshToken]);

  const login = async (values) => {
    if (!values) {
      return;
    }

    try {
      const { refreshToken: token, userData: user } = values;
      localStorage.setItem(CLIENT_REFRESH_NAME, token);
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
    } catch (error) {
      console.error('Login failed to parse JSON:', error);
    }
  };

  async function selectCompany(user) {
    await refresh(user.barn_id, window.localStorage.getItem(CLIENT_REFRESH_NAME))  
  }

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        login,
        logout,
        selectCompany,
        runProtected,
        refresh,
        checkToken
      }}
    >
      <Outlet></Outlet>
    </AuthContext.Provider>
  );
};

export default AuthLayout;




