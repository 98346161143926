/**@jsxImportSource @emotion/react */

//import React, { useState, useRef, useEffect } from "react";
import CustomDivTable from "../../components/custom-table/custom-div-table";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";

const rawFields = ['approximateWeightLbs', 'cls', 'spudDiameterInInches', 'spudLengthInInches'];

export default function SessionTableLast(props) {
  const { data, rawData, sessionId, profile } = props

  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const cleanField = (text) => {
    return text ? text : "0"
  }

  const exportData = () => {
    if (!rawData?.length) return;

    const headers = 'Approximate Weight Lbs,Class,Spud Diameter In Inches,Spud Length In Inches';
    const rows = rawData?.map((row) => {
      return rawFields.map(key => row[key])
        .map(cleanField)
        .join(',');
    });

    const csvString = [headers, ...rows].join('\n');
    const blob = new Blob([csvString], {type: 'text/csv'});

    const link = document.createElement('a');
    link.download = `${sessionId}.csv`;
    link.href = URL.createObjectURL(blob)
    link.click();
  };
  
  const columns = [
    {
      name: "size",
      default: () => "",
      valFn: (row) => {
        const label = profile === 'USDA'
          ? row?.size
          : row?.name;

        return `${ cleanField(label) }`
      },
      display: "Size",
    },
    {
      name: "count",
      default: () => "",
      valFn: (row) => `${ cleanField(row?.count) }`,
      display: "Count",
    },
    {
      name: "total_weight",
      default: () => "",
      valFn: (row) => `${ cleanField(row?.total_weight?.toFixed(2)) }`,
      display: "Weight",
    },
    {
      name: "diseased",
      default: () => "",
      valFn: (row) => `${ cleanField(row?.defective) }`,
      display: "Defective",
    },
    {
      name: "export",
      default: () => "",
      valFn: () => '',
      display: () => (
        <button
          onClick={exportData}
          style={{
            border: 'none',
            backgroundColor: '#F0744D',
            color: 'white',
            cursor: 'pointer',
            padding: '8px 16px',
            borderRadius: '16px',
            fontWeight: 'bold'
          }}
        >
          Export
        </button>
      )
    }
  ];

  return (
    <CustomDivTable
      columns={columns}
      rows={data || []}
      // selectionMode="single"
      // onSelect={(it) => handleToUser(it)}
      //filterKeys={["first_name", "last_name", "email"]}
      showSearch={false}
    />
  );
}