import { useContext } from 'react';
import { useMutation as reactQueryUseMutation } from '@tanstack/react-query';

import AuthContext from '../context/auth-context';

export function useMutation(props) {
  const { isProtected = true } = props;

  const { runProtected } = useContext(AuthContext);

  const query = reactQueryUseMutation({
    ...props,
    mutationFn: async (...args) => {
      let data = null;
      if (isProtected) {
        data = await runProtected(props.mutationFn, ...args);
      } else {
        data = await props.mutationFn(...args);
      }

      return data;
    },
  });

  return query;
}
