/**@jsxImportSource @emotion/react */

import { useMemo, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

// import css from "./custom-div-table.module.scss";
import { css } from "./css";
import { useTheme } from "@emotion/react";

function generateFilterObject(keys, searchText) {
  const filterObject = {};
  for (const key of keys) {
    filterObject[key] = {
      value: searchText?.toLowerCase() || "",
    };
  }
  return filterObject;
}

export default function CustomDivTable(props) {
  const {
    sortable = false,
    title,
    columns,
    rows,
    count,
    selection,
    onSelect,
    selectionMode = 'single',
    filterKeys,
    reorderableColumns = false,
    reorderableRows = false,
    onRowReorder,
    sortField,
    sortOrder,
    noTableSort,
    showSearch = true,
    searchOutside = false,
    activeBtnGroup,
    groupBy,
    hasFooter,
    rowGroupHeaderTemplate,
    multiSortMeta = [],
    onPage,
    rowCount,
  } = props;
  const [searchText, setSearchText] = useState("");

  const [tableSortField, setTableSortField] = useState(sortField);
  const [tableSortOrder, setTableSortOrder] = useState(sortOrder);
  const [tableRows, setTableRows] = useState(rowCount);
  const [tablePage, setTablePage] = useState(0);

  const searchTimeout = useRef(null);

  const theme = useTheme()

  useEffect(() => {
    if (onPage) {
      onPage({
        offset: 0,
        limit: 10,
        sort: sortField,
        order: sortOrder,
      });
    }
  }, [onPage, sortField, sortOrder]);

  const filters = useMemo(
    () => filterKeys && generateFilterObject(filterKeys, searchText),
    [filterKeys, searchText],
  );

  const rowObjs = useMemo(() => {
    return rows
      ?.map((data, i) => {
        const row = { data };
        for (const field of columns) {
          row[field.name] = field.valFn
            ? field.valFn(data, i)
            : data[field.name];
        }
        return row;
      })
      .filter((item) => {
        if (onPage || !filters) return true;
        for (const key of Object.keys(filters)) {
          if (
            item.data[key]?.toLowerCase().includes(searchText?.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
  }, [rows, columns, filters, searchText, onPage]);

  const tableRowsPerPage = useMemo(() => {
    const options = [5, 10, 25, 50];
    const filteredOptions = options.filter(
      (option) => option < rowObjs?.length,
    );
    filteredOptions.push(rowObjs?.length);

    return filteredOptions;
  }, [rowObjs?.length]);

  const handleSearch = (event) => {
    clearTimeout(searchTimeout.current);

    searchTimeout.current = setTimeout(() => {
      if (onPage) {
        setTablePage(0);

        let order = "desc";

        if (tableSortOrder) {
          order = tableSortOrder === 1 ? "asc" : "desc";
        }

        onPage({
          offset: 0,
          limit: tableRows,
          sort: tableSortField,
          order: order,
          search: event.target.value,
        });
      }
    }, 300);

    setSearchText(event.target.value);
  };

  const footer = `${rows ? rows.length : 0} Results`;
  const columnEls = (columns || []).map((col) => {
    if (activeBtnGroup === "upcoming" && col.name === "numAttended") {
      return false;
    }
    if (activeBtnGroup === "active" && col.name === "archive") {
      return false;
    }

    const headerEl = col?.helper ? (
      <>
        {col?.display} <span className={css.helper}>{col.helper}</span>
      </>
    ) : (
      col?.display
    );

    const getColWidth = (name) => {
      const types = {
        archive: 100,
        remove: 100,
        cancel: 100,
        splits: 75,
        edit: 70,
        export: 70,
        go: 40,
        default: null,
      };

      return types[name || "default"];
    };

    return (
      <Column
        key={col.name}
        field={col.name}
        header={headerEl}
        sortable={col.sortable}
        style={{
          textAlign: col.actionCol ? "right" : null,
          width: getColWidth(col.name),
          // col.actionCol && col.name === "archive"
          //   ? 100
          //   : col.actionCol && col.name === "remove"
          //   ? 100
          //   : col.actionCol && col.name === "cancel"
          //   ? 100
          //   : col.actionCol && col.name === "splits"
          //   ? 75
          //   : col.actionCol && col.name === "edit"
          //   ? 70
          //   : col.actionCol && col.name === "go"
          //   ? 40
          //   : null,
        }}
      />
    );
  });

  const pageFn = onPage
    ? (e) => {
        let order = undefined;
        if (e.sortField && e.sortOrder) {
          order = e.sortOrder === 1 ? "asc" : "desc";
        }

        onPage({
          offset: e.page * e.rows,
          limit: e.rows,
          sort: e.sortField,
          order,
          search: searchText,
        });

        setTableSortField(e.sortField);
        setTableSortOrder(e.sortOrder);
        setTableRows(e.rows);
        setTablePage(e.page);
      }
    : undefined;

  const handleOnSelect = onSelect
    ? (e) => onSelect(e.value.data)
    : undefined;

  return (
    <>
      {searchOutside && showSearch && filterKeys?.length > 0 && (
        <div className="tableContain" data-type="searchoutside">
          <div className="tableContain__search">
            <InputText
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
            />
          </div>
        </div>
      )}

      <div css={css(theme)} className="tableContain">
        {title && <h3 className="tableContain__title">{title}</h3>}
        {!searchOutside && showSearch && filterKeys?.length > 0 && (
          <div className="tableContain__search">
            <InputText
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
            />
          </div>
        )}
        <DataTable
          value={rowObjs}
          onPage={pageFn}
          lazy={!!pageFn}
          first={tableRows && tablePage ? tableRows * tablePage : null}
          onSort={(e) => pageFn({ ...e, page: 1 })}
          paginator={count || rowObjs?.length >= tableRows}
          rows={tableRows}
          totalRecords={count}
          // stripedRows
          rowsPerPageOptions={tableRowsPerPage}
          footer={hasFooter ? footer : null}
          selectionMode={selectionMode}
          selection={selection}
          onSelectionChange={handleOnSelect}
          reorderableColumns={reorderableColumns}
          reorderableRows={reorderableRows}
          onRowReorder={(event) => onRowReorder(event)}
          sortField={tableSortField}
          sortMode={multiSortMeta?.length ? "multiple" : "single"}
          multiSortMeta={multiSortMeta}
          sortOrder={tableSortOrder}
          // rowGroupMode="subheader"
          rowGroupMode={rowGroupHeaderTemplate ? "subheader" : null}
          rowGroupHeaderTemplate={rowGroupHeaderTemplate}
          groupRowsBy={groupBy}
          // style={{ width: "100%" }}
          // css={css(null, true)}
          responsiveLayout='stack'
          breakpoint="600px"
        >
          {reorderableColumns && reorderableRows && (
            <Column rowReorder style={{ width: "3rem" }} />
          )}
          {columnEls}
        </DataTable>
      </div>
    </>
  );
}