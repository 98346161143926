/**@jsxImportSource @emotion/react */

import React, { useMemo, useEffect, useState, useCallback, useRef } from "react";
// import CustomDivTable from "../../components/custom-table/custom-div-table";

// import { useQuery } from "../../hooks";


import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";
// import moment from "moment";

import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from "react-chartjs-2";

// Chart.register(ChartDataLabels);

export default function SystemChart(props) {
  const { data, profile } = props;

  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const chartContainerRef = useRef();
  const chartRef = useRef();

  const countTotal = (row) => {
    const defective = row?.defective ?? 0
    const count = row?.count ?? 0

    return count - defective
  }

  const buildDataSet = useCallback((sourceData) => {
    return [
      {
        label: "Defective",
        data: (sourceData || []).map((data) => data?.defective ?? 0),
        backgroundColor: [
          "rgba(255,30,30,0.7)",
        ],
        borderColor: "red",
        borderWidth: 1
      },
      {
        label: "Normal",
        data: (sourceData || []).map(countTotal),
        backgroundColor: [
          "rgba(75,192,192,0.7)",
        ],
        borderColor: "gray",
        borderWidth: 1
      },
    ]
  }, []);

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: "Last Session Summary"
      },
      legend: {
        display: false
      }
    },
    //parsing: false,
    //animation: false,
    responsive: true,
    //indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Size'
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Count'
        }
      }
    }
  };

  const createLabels = useCallback((data) => {
    if (profile === 'USDA') {
      return `${data?.size}`;
    }

    return `${data?.name}`;
  }, [profile]);

  const [chartData, setChartData] = useState({
      labels: (data || []).map(createLabels), 
      datasets: buildDataSet(data),
    });

  useEffect(()=> {
    setChartData({
      labels: (data || []).map(createLabels), 
      datasets: buildDataSet(data),
    })
  },[data, buildDataSet, createLabels]) // empty array means it only run once

  return (
  <div css={css}>
    <div className="chart-container">
      <Bar
        data={chartData}
        options={chartOptions}
      />
    </div>
  </div>
  );
}

