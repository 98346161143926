const pako = require('pako');

const int8s = 1;
const fp16s = 3;

// Convert float16 to JavaScript float32
function decodeFloat16(bits) {
  let sign = (bits & 0x8000) ? -1 : 1;
  let exponent = (bits >> 10) & 0x1F;
  let fraction = bits & 0x3FF;

  if (exponent === 0) {
      return sign * (fraction / Math.pow(2, 24));
  } else if (exponent === 31) {
      return fraction ? NaN : sign * Infinity;
  }

  return sign * Math.pow(2, exponent - 15) * (1 + fraction / Math.pow(2, 10));
}

export default function parseRawData(compressedData) {
  let decompressedData = pako.inflate(compressedData);

  if (!decompressedData) {
    return [];
  }
  
  let spuds = [];
  const spudsBytes = Array.from(decompressedData)
  while (spudsBytes.length > 0) {
    let i8s = [];
    for (let i8 = 0; i8 < int8s; i8++) {        
      i8s.push(spudsBytes.shift());        
    }
    i8s = new Int8Array(i8s, 0, int8s);
    let int8Array = i8s;

    let f16s = [];
    for (let f16 = 0; f16 < fp16s; f16++) {
      let a = spudsBytes.shift();
      let b = spudsBytes.shift();
      f16s.push(a);
      f16s.push(b);
    }
    f16s = new Uint8Array(f16s);
    let float32Array = Array.from(new Uint16Array(f16s.buffer, 0, 3), decodeFloat16);

    spuds.push({
      cls: int8Array[0],
      approximateWeightLbs: float32Array[0],
      spudDiameterInInches: float32Array[1],
      spudLengthInInches: float32Array[2],
    });
  }

  return spuds;
}
