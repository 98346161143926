import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Marketing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check user status and redirect immediately
    const user = window.localStorage.getItem('user');

    // JER - note to self to add the redirect back in later
    // if (user) {
      navigate("/login");
    // } else {
    //   console.log('User not found, redirecting to external site..');
    //   window.location.href = "https://spudbud.ai";
    // }
  }, [navigate]);

  // Return null to prevent rendering the component
  return null;
};

export default Marketing;