/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import { Icon } from "../../components";

import VideoComponent from "../../components/video-component/video-component";
import CameraSelectorComponent from "../../components/camera-selector-component/camera-selector-component";

import SystemHistory from "../system/session-history";

import { cssSystemTray as tempCss } from "./css";
import SystemCard from "../system/system-card";

export default function SystemTray(props) {
  const {
    activeSystem,
    setActiveSystem,
    refetchSystem,
  } = props;

  const theme = useTheme()
  const css = tempCss(theme)?.systemTray

  const [activeCamera, setActiveCamera] = useState(null);
  const [fullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    if(activeSystem?.camera?.length > 0) 
      setActiveCamera(activeSystem?.camera[0])
  }, [activeSystem])

  const actionBlock = (
    <div css={css?.actionBlock}>
      {setActiveSystem && (
        <div css={css?.actionBlock?.back} onClick={() => setActiveSystem(null)}>
          <Icon icon="ChevronLeft" />
          Dashboard
        </div>
      )}

      <div css={css?.actionBlock?.expand} onClick={() => {
        setFullScreen(fullScreen === 'false' ? 'true' : 'false')
      }}>
        <Icon icon="Expand" />
      </div>
    </div>
  )

  const videoBlock = (
    <div css={css.videoBlock} className="videoContentArea">
      
      {/* {currentCamera && <VideoComponent currentCamera={currentCamera} />} */}
      <VideoComponent currentCamera={activeCamera} full_screen={fullScreen} />
      
      <CameraSelectorComponent activeCamera={activeCamera} setActiveCamera={setActiveCamera} activeSystem={activeSystem} refetch={refetchSystem}/>

    </div>
  );

  const currentStatusBlock = (
    <div css={css?.statusBlock}>
      <SystemCard
        data={activeSystem}
        id={activeSystem.id}
        setActiveSystem={setActiveSystem}
        isActive={true}
        showImage={false}
      />
    </div>
  )


  return (
    <div css={css}>
      {actionBlock}

      <div css={css?.content}>
        {videoBlock}
        {currentStatusBlock}
      </div>

      <SystemHistory systemId= { activeSystem?.id } />
    </div>
  );
}