/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useContext, useState } from "react";
import { Formik, Form } from "formik";

import Client from "../../client";
import { useMutation } from "../../hooks";
import AuthContext from "../../context/auth-context";

import {
  Button,
  ErrorBox,
  FormElement,
  FullLogo,
} from '../../components';

import ResetPassword from "./login-reset-password";

import { css } from "./css";

const initialValues = {
  email: "",
  password: "",
};

const validate = () => ({});

const Login = () => {
  // const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const { mutateAsync: onSubmit, isLoading } = useMutation({
    mutationKey: "login_user",
    mutationFn: async (data) => Client.post("/auth/login", data),
    onSuccess: (data) => {
      login(data);
    },
    onError: () => {
      setHasError(true);
    },
    isProtected: false
  });

  // const handleSignup = () => {
  //   navigate("/signup");
  // };

  const handlePasswordReset = async () => {
    // Perform password reset function here
    try {
      let email = document.getElementById("email").value;

      await Client.post("/auth/forgot-password", { email });
      alert("Check your email for a link to reset your password!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };

  const theme = useTheme();

  function handleErrorBtn() {
    setTimeout(() => setHasError(false), 500);
  }

  const ressetPassword = isResettingPassword && (
    <ResetPassword
      toLogin={() => setIsResettingPassword(false)}
      handlePasswordReset={handlePasswordReset}
    />
  );

  const header = <FullLogo />;

  const form = (formik) => (
    <div css={css?.formBlock(theme)} style={{ opacity: isResettingPassword ? 0 : null }}>
      <div css={css?.formBlock(theme)?.intro(theme)}>
        <h1>Log in to your account</h1>
        <p>Welcome back! Please enter your details.</p>
      </div>

      <div css={css?.formBlock(theme)?.elements}>
        <FormElement
          element="input"
          id="email"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Email"
        />

        <FormElement
          element="password"
          id="password"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          label="Password"
        />
      </div>

      <div css={css?.formBlock(theme).forgotlink(theme)}>
        <span onClick={() => setIsResettingPassword(true)}>Forgot password?</span>
        {/* <span onClick={() => handleSignup()}>Register</span> */}
      </div>
      <Button
        label="Sign in"
        onClick={formik.handleSubmit}
        loading={isLoading}
        disabled={isLoading || Object.values(formik.errors).length || hasError}
        type="submit"
      />
      {/* <div css={css.signup(theme)}>
        Don't have an account? <span onClick={() => setIsResettingPassword(true)}>Sign Up</span>
      </div> */}
    </div>
  );

  return (
    <div css={css.container(theme)}>
      <div css={css.block}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              {hasError && <ErrorBox onClick={handleErrorBtn} />}
              {header}
              {ressetPassword}
              {form(formik)}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;