/**@jsxImportSource @emotion/react */

import React, { useEffect, useState, useMemo } from "react";
//import CustomDivTable from "../../components/custom-table/custom-div-table";

import { useQuery } from "../../hooks";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";
//import moment from "moment";

import SystemChart from "./session-chart";
import SessionTableLast from "./session-table-last"
import SessionTableAllRows from "./session-table-allrows"
import SessionTableAllSessions from "./session-table-allsessions"

import parseRawData from '../../utils/parseRawData';
import analyzeRawData from '../../utils/analyzeRawData';

export default function SystemHistory(props) {
  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const { systemId, sessionId, profile, setProfile } = props;
  const [decompressData, setDecompressData] = useState(null);

  const { data, refetch } = useQuery(
    `/system/${systemId}/data?profile=${profile}&session=${sessionId}`,
    { refetchInterval: 15000 }
  );

  const { data: rawData, refetch: refetchRaw } = useQuery(
    `/system/${systemId}/raw?session=${sessionId}`,
    { refetchInterval: 15000 }
  );

  useEffect(() => {
    async function decompress() {
      const arrayBuffer = await rawData.arrayBuffer();
      const parsedData = parseRawData(new Uint8Array(arrayBuffer));
      setDecompressData(parsedData);
    }

    if (rawData) {
      decompress();
    } else {
      setDecompressData(null);
    }
  }, [rawData, profile]);

  const dataSummary = useMemo(() => {
    if (!decompressData?.length) {
      return null;
    }

    return analyzeRawData(profile, decompressData);
  }, [profile, decompressData]);

  const profiles = ['SANJEEV', 'USDA', 'WAYNE'];
  const profileBtns = profiles.map((name) => {
    const classes = [];
    if (name === profile) {
      classes.push('selected');
    }

    return (
      <button
        key={name}
        onClick={() => setProfile(name)}
        className={classes.join(' ')}
      >
        {name}
      </button>
    );
  })

  // title section
  const recent = data?.data;
  const titleSection = (
    <div css={css?.mostRecentSession}>
      <div>
        <div>Session: { recent?.session_id }</div>
      </div>
      {/* <div>Started At: { formattedDate }</div> */}
      <div>
        {profileBtns}
      </div>
    </div>
  )

  return (
    <div css={css}>
      {titleSection}

      <SystemChart 
        data={dataSummary}
        profile={profile}
      />

      <SessionTableLast
        data={dataSummary}
        rawData={decompressData}
        profile={profile}
        sessionId={recent?.session_id}
      />

      {/* <SessionTableAllRows
        data={data}
      /> */}

      <SessionTableAllSessions 
        systemId={props?.systemId}
        profile={profile}
      />

    </div>
  );
}