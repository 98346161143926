/**@jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { useMutation } from "../../hooks";
import { useSearchParams } from 'react-router-dom';

import CustomDivTable from "../../components/custom-table/custom-div-table";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";
import moment from "moment";

import APIClient from '../../client';

export default function SessionTableAllSessions(props) {
  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const [, setSearchParams] = useSearchParams();

  const [sessions, setSessions] = useState([]);
  const [end, setEnd] = useState(false);

  const { mutate: getData } = useMutation({
    mutationFn: (options = {}) => {
      const { offset, recent } = options;

      let url = `/system/${props?.systemId}/data-all-sessions?profile=${props.profile}`;

      url += '&limit=15';
      if (offset) {
        url += `&offset=${offset}`;
      }
      if (recent) {
        url += '&recent=true';
      }

      return APIClient.get(url)
    },
    isProtected: true,
    onSuccess: (res) => {
      // zip data to avoid duplicates
      const arr = Object
        .values(
          [...sessions, ...res.data]
            .reduce((map, row) => {
              map[row.id] = row;
              return map;
            }, {})
        )
        .sort((a, b) => a.created_at < b.created_at ? 1 : -1);

      const isEnd = !res.data.length || !!arr.find(row => row.created_at === res.end);

      setSessions(arr);
      setEnd(isEnd);
    }
  });

  function grabMoreData() {
    if (sessions.length) {
      getData({ offset: sessions[sessions.length - 1].created_at });
    } else {
      getData();
    }
  }

  useEffect(() => {
    if (!end && !sessions.length) {
      getData();
    }

    const refreshTimeout = setTimeout(() => {
      if (sessions.length) {
        getData({
          offset: sessions[0].created_at,
          recent: true
        });
      } else {
        getData();
      }
    }, 15000);

    return () => {
      clearTimeout(refreshTimeout);
    };
  }, [getData, end, sessions]);

  function handleRowSelect(row) {
    setSearchParams((params) => {
      const existingParams = [...params.entries()].reduce((map, entry) => {
        const [key, val] = entry;
        map[key] = val;
        return map;
      }, {});

      return {
        ...existingParams,
        session: row.id
      };
    })

    window.scrollTo(0, 0);
  }

  const cleanField = (text) => {
    return text ? text : "0"
  }
  
  //const data = activeData
  const detail_columns = [
    {
      name: "session_id",
      default: () => "",
      valFn: (data) => `${ cleanField(data?.session_id) }`,
      display: "Session Id",
    },
    {
      name: "started_at",
      default: () => "",
      valFn: (data) => `${ cleanField(moment(data?.dates?._min.updated_at).format('MM/DD/YYYY hh:mm:ssa')) }`,
      display: "Start Time",
    },
    {
      name: "updated_at",
      default: () => "",
      valFn: (data) => `${ cleanField(moment(data?.dates?._max.updated_at).format('MM/DD/YYYY hh:mm:ssa')) }`,
      display: "End",
    },
    {
      name: "count",
      default: () => "",
      valFn: (data) => `${ cleanField(data?.aggregate_data?._sum.count) }`,
      display: "Count",
    },
    {
      name: "total_weight",
      default: () => "",
      valFn: (data) => `${ cleanField(data?.aggregate_data?._sum.total_weight?.toFixed(2)) }`,
      display: "Weight",
    },
    {
      name: "diseased",
      default: () => "",
      valFn: (data) => `${ cleanField(data?.aggregate_data?._sum.diseased) }`,
      display: "Defective",
    }
    
  ];

  // title section
  const titleSection = (
    <div css={css?.mostRecentSession}>
      <div>All Sessions</div>
    </div>
  )

  const moreBtn = end
    ? null
    : (
      <div>
        <button
          onClick={grabMoreData}
          css={css?.moreBtn}
        >
          Show More
        </button>
      </div>
    );

  return (
    <>
      <br />
      {titleSection}
      
      <CustomDivTable
        columns={detail_columns}
        rows={sessions}
        // selectionMode="single"
        onSelect={handleRowSelect}
        //filterKeys={["first_name", "last_name", "email"]}
        showSearch={false}
      />

      {moreBtn}
    </>
  );
}